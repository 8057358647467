/*
 * @Descripttion: 
 * @version: 
 * @Author: chenyu
 * @Date: 2024-06-22 15:50:19
 * @LastEditors: chenyu
 * @LastEditTime: 2024-06-29 17:12:14
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/fontFamily/font.css'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
Vue.use(VueAwesomeSwiper)
// import 'amfe-flexible';

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
