/*
 * @Descripttion: 
 * @version: 
 * @Author: chenyu
 * @Date: 2024-06-22 15:50:19
 * @LastEditors: chenyu
 * @LastEditTime: 2024-06-29 11:26:30
 */
/*
 * @Descripttion: 
 * @version: 
 * @Author: chenyu
 * @Date: 2024-06-22 15:50:19
 * @LastEditors: chenyu
 * @LastEditTime: 2024-06-24 09:42:10
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import pubsub from "pubsub-js";
Vue.use(VueRouter)

const routes = [
  {
    path:'/',
    redirect: '/main/index'
  },
  {
    path: '/main',
    name: 'main',
    component: () => import('../views/index.vue'),
    children:[
      {
        path:'index',
        name:'index',
        component:()=>import('../views/home.vue'),
        meta:{
          index:0
        }
      },
      {
        path:'brandIntroduction',
        name:'brandIntroduction',
        component:()=>import('../views/brandIntroduction.vue'),
        meta:{
          index:1
        }
      },
      {
        path:'marketing',
        name:'marketing',
        component:()=>import('../views/marketing.vue'),
        meta:{
          index:2
        }
      },
      {
        path:'Introduction',
        name:'Introduction',
        component:()=>import('../views/Introduction.vue'),
        meta:{
          index:3
        }
      },
      {
        path:'joinUs',
        name:'joinUs',
        component:()=>import('../views/joinUs.vue'),
        meta:{
          index:4
        }
      },
      {
        path:'repertory',
        name:'repertory',
        component:()=>import('../views/repertory.vue'),
        meta:{
          index:5
        }
      }
    ]
  },
]

const router = new VueRouter({
  routes
})

router.afterEach((to,from)=>{
  pubsub.publish('routingJump',to)
})

export default router
